import * as React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Row} from "react-bootstrap";
import {graphql, useStaticQuery} from "gatsby";
import {getImage} from "gatsby-plugin-image";
import PortfolioCard from "./PortfolioCard";

export default function LastProjects() {
    const { t } = useTranslation();
    const { allPortfolioDataJson } = useStaticQuery(
        graphql`
            query {
              allPortfolioDataJson(limit: 3) {
                nodes {
                  name
                  link
                  description
                  src {
                    childImageSharp {
                      gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          `
    );

    return (
        <section>
            <h2 className="my-3 my-xxl-4 pt-3 pt-xl-4 pb-1">{t('ourPortfolioLastProjectsText2', {projects: 3})}</h2>
            <Row role="group">
                {allPortfolioDataJson.nodes.map((item, index) => {
                    const portfolioImage = getImage(item.src);

                    return (
                        <PortfolioCard item={item} portfolioImage={portfolioImage} key={`last-${index}`} />
                    )
                })}
            </Row>
        </section>
    )
}